import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import FsLightbox from 'fslightbox-react';

import { Breadcrumb, Layout } from '@app/components';
import { useLocale } from '@app/hooks';

import image1 from '@app/images/visual-media/visual-media-1.jpg';
import image2 from '@app/images/visual-media/visual-media-2.jpg';
import image3 from '@app/images/visual-media/visual-media-3.jpg';
import image4 from '@app/images/visual-media/visual-media-4.jpg';
import image5 from '@app/images/visual-media/visual-media-5.jpg';
import image6 from '@app/images/visual-media/visual-media-6.jpg';
import image7 from '@app/images/visual-media/visual-media-7.jpg';
import image8 from '@app/images/visual-media/visual-media-8.jpg';
import image9 from '@app/images/visual-media/visual-media-9.jpg';
import image10 from '@app/images/visual-media/visual-media-10.jpg';
import image11 from '@app/images/visual-media/visual-media-11.jpg';
import image12 from '@app/images/visual-media/visual-media-12.jpg';
import image13 from '@app/images/visual-media/visual-media-13.jpg';
import image14 from '@app/images/visual-media/visual-media-14.jpg';
import image15 from '@app/images/visual-media/visual-media-15.jpg';
import image16 from '@app/images/visual-media/visual-media-16.jpg';
import image17 from '@app/images/visual-media/visual-media-17.jpg';

import '@app/styles/pages/visual-media.scss';

const VisualMedia: React.FC = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const SCOPE_OPTIONS = {
    scope: 'pages.VisualMedia',
  };
  const { t } = useLocale();

  return (
    <Layout>
      <Helmet title={t('labels.pageTitle', SCOPE_OPTIONS)} />
      <Breadcrumb
        items={[
          { path: '/', name: t('labels.home', SCOPE_OPTIONS) },
          { path: '/visual-media', name: t('labels.visualMedia', SCOPE_OPTIONS) },
        ]}
      />

      <section className="py-20">
        <div className="container mx-auto px-1">
          <div className="grid lg:grid-cols-4 grid-cols-2 gap-6">
            <button className="video-card" onClick={() => openLightboxOnSlide(1)}>
              <img src={image1} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(2)}>
              <img src={image2} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(3)}>
              <img src={image3} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(4)}>
              <img src={image4} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(5)}>
              <img src={image5} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(6)}>
              <img src={image6} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(7)}>
              <img src={image7} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(8)}>
              <img src={image8} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(9)}>
              <img src={image9} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(10)}>
              <img src={image10} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(11)}>
              <img src={image11} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(12)}>
              <img src={image12} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(13)}>
              <img src={image13} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(14)}>
              <img src={image14} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(15)}>
              <img src={image15} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(16)}>
              <img src={image16} className="video-card__image" alt="" />
            </button>
            <button className="video-card" onClick={() => openLightboxOnSlide(17)}>
              <img src={image17} className="video-card__image" alt="" />
            </button>
          </div>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={[
              image1,
              image2,
              image3,
              image4,
              image5,
              image6,
              image7,
              image8,
              image9,
              image10,
              image11,
              image12,
              image13,
              image14,
              image15,
              image16,
              image17,
            ]}
            slide={lightboxController.slide}
          />
        </div>
      </section>
    </Layout>
  );
};

export default VisualMedia;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
